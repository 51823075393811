.header {
  flex-direction: column;
  max-width: 100vw;
  height: 85vh;
  display: flex;
  position: relative;
}

.header__nav {
  background-color: #fffffff7;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 9.6rem;
  padding: 0 2.4rem;
  display: flex;
  position: relative;
}

.header__nav__image__container {
  max-width: 6rem;
}

.header__nav__image {
  object-fit: cover;
  width: 100%;
}

.header__main__nav__list:active {
  transition: all .3s;
  transform: translate(.2rem, .2rem);
}

.sticky .header__nav {
  z-index: 9999;
  transition: all .3s;
  position: fixed;
  top: 0;
}

.header__main__nav {
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  font-size: 2rem;
  list-style: none;
  transition: all .3s;
  display: flex;
}

.header__main__nav__links:link, .header__main__nav__links:visited {
  color: var(--dark-color);
  text-decoration: none;
  transition: all .3s;
}

.header__main__nav__links:hover, .header__main__nav__links:active {
  color: var(--dark-color-tint);
  transition: all .3s;
}

.header__main__nav__list .button {
  background-color: var(--primary-color);
  color: var(--dark-color-shade);
  border: .2rem solid #e3fafc;
  border-radius: 1rem;
  padding: 1.2rem 2.4rem;
  font-weight: bold;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 .2rem .97rem #00000080;
}

.header__main__nav__list .button:hover {
  color: var(--dark-color);
  border: solid .2rem var(--primary-color);
  background-color: #fffffff7;
  box-shadow: 0 .2rem 1.2rem #00000080;
}

.header__main__nav__list .button:active {
  color: var(--dark-color-tint);
  background-color: var(--background-color-light);
  transform: translateY(.2rem);
  box-shadow: 0 .1rem .8rem #000000de;
}

.header__background__image-container {
  z-index: -1;
  background-image: url("background-header.22ab9bc7.jpg");
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 85vh;
  position: absolute;
}

.image-overlay {
  background-color: #000000ab;
  width: 100vw;
  height: 85vh;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

/*# sourceMappingURL=index.5aa9d722.css.map */
