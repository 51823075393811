@import "variables.css";

.header {
  display: flex;
  flex-direction: column;

  max-width: 100vw;
  height: 85vh;
  position: relative;

  /* overflow: hidden; */
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.97);
  width: 100vw;
  position: relative;

  /* For Sticky nav */
  height: 9.6rem;
  padding: 0 2.4rem;
}

/* For the header logo */
.header__nav__image__container {
  max-width: 6rem;
}

.header__nav__image {
  width: 100%;
  object-fit: cover;
}

/* main nav list active*/
.header__main__nav__list:active {
  transform: translate(0.2rem, 0.2rem);
  transition: all 0.3s;
}

/* for sticky nav */

.sticky .header__nav {
  /* margin-top: 9.6rem; */
  position: fixed;
  top: 0;
  transition: all 0.3s;
  z-index: 9999;
}

/* For the header Navigation Links */
.header__main__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-size: 2rem;
  gap: 1.8rem;
  transition: all 0.3s;
}

/* header nav */
.header__main__nav__links:link,
.header__main__nav__links:visited {
  text-decoration: none;
  color: var(--dark-color);
  transition: all 0.3s;
}

.header__main__nav__links:hover,
.header__main__nav__links:active {
  color: var(--dark-color-tint);
  transition: all 0.3s;
}

.header__main__nav__list .button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--dark-color-shade);
  font-weight: bold;

  padding: 1.2rem 2.4rem;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.97rem rgba(0, 0, 0, 0.5);
  border: solid 0.2rem #e3fafc;
  transition: all 0.3s;
}

/* Contact Button */
.header__main__nav__list .button:hover {
  background-color: rgba(255, 255, 255, 0.97);
  color: var(--dark-color);
  box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.5);
  border: solid 0.2rem var(--primary-color);
}

.header__main__nav__list .button:active {
  transform: translateY(0.2rem);
  color: var(--dark-color-tint);
  background-color: var(--background-color-light);
  box-shadow: 0 0.1rem 0.8rem rgba(0, 0, 0, 0.87);
}

/* Background Image */

.header__background__image-container {
  width: 100vw;
  height: 85vh;
  position: absolute;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-image: url(../image/background-header.jpg);
}

.image-overlay {
  width: 100vw;
  height: 85vh;
  background-color: rgba(0, 0, 0, 0.67);
}

/* mobile */
/* MOBILE */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}
